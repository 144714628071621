import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { generatePosologies } from 'src/common_functions'
import {
  SIGNING_CARD_STATUS
} from 'src/constants'

import PrescriptionInfo from './prescription_info/prescription_info'
import Card from './card/card'

import { convertFromItemWithLinesToBasket, convertFromRealmToSPMS, getMedicinesFromPrescription } from '../../utils'
import { useAdaptivePolyglot } from '../../hooks'

function isValidCardStatus (cardStatus) {
  return cardStatus === SIGNING_CARD_STATUS.AVAILABLE || cardStatus === SIGNING_CARD_STATUS.NO_PICTURE
}

export const MainContent = React.memo(function MainContent ({
  cardStatus, serverUrl, shouldRetryServer, prescription, patients, professionalInfo, addEmailsAndEmitPrescription,
  prescriptionErrors
}) {
  const [spmsPrescription, setSpmsPrescription] = useState(null)
  const [basketItems, setBasketItems] = useState(null)

  const tPosology = useAdaptivePolyglot('prescriptionList')

  const patient = useMemo(() => !prescription ? null : patients[prescription.healthId], [prescription, patients])

  const internalAddEmailsAndEmitPrescription = useCallback((emails, callWithState) => {
    addEmailsAndEmitPrescription(spmsPrescription, basketItems, emails, callWithState)
  }, [addEmailsAndEmitPrescription, spmsPrescription, basketItems])

  const convertToSpmsFormat = useCallback(() => {
    if (prescription) {
      convertFromItemWithLinesToBasket(prescription)
        .then(async basketItems => {
          setBasketItems(basketItems)
          const posologies = await generatePosologies(basketItems, tPosology)
          const medicinesObj = await getMedicinesFromPrescription(prescription)
          return convertFromRealmToSPMS(prescription, medicinesObj, patient, professionalInfo, posologies)
        })
        .then(setSpmsPrescription)
        .catch(error => {
          console.error(error)
          setSpmsPrescription(null)
          setBasketItems(null)
        })
    }
  }, [prescription, patient, professionalInfo, tPosology])

  useEffect(() => {
    setSpmsPrescription(null)
    setBasketItems(null)
    convertToSpmsFormat()
  }, [prescription, convertToSpmsFormat, tPosology])

  const shouldShowSignButton = useMemo(() => isValidCardStatus(cardStatus), [cardStatus])

  return <div className='d-flex overflow-auto flex-column px-2 mainContent'>
    <div
      className={`d-flex bd-highlight justify-content-center flex-wrap align-items-center mt-2 mb-2`}>
      {prescription && <PrescriptionInfo
        photoUrl={patient.picture} fullName={patient.fullName}
        partialName={patient.fullName} date={patient.birthDate}
      />}
    </div>
    <div className='accordion' id='accordion'>
      {!prescription && <div className='d-flex align-items-center justify-content-center text-center'>
        Selecione uma prescrição
      </div>}
      {(prescription && !spmsPrescription) && <div
        className='d-flex align-items-center justify-content-center text-center'>
        A preparar a prescrição
      </div>}
      {(prescription && basketItems && spmsPrescription) && <Card
        number={1}
        items={basketItems}
        hash={null}
        prescription={prescription}
        prescriptionId={prescription.id}
        serverUrl={serverUrl}
        newSignature={() => {}}
        shouldShowSignButton={shouldShowSignButton}
        key={prescription.id}
        addEmailsAndEmitPrescription={internalAddEmailsAndEmitPrescription}
        patientEmail={patient.email}
        prescriptionErrors={prescriptionErrors}
      />}
    </div>
  </div>
})
