import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import Prescription from '../../../models/prescription'

import './side_bar_item.css'


const SideBarItem = React.memo(function SideBarItem ({
  id, healthId, name, date, selectedItem, selectPrescription
}) {

  const prescriptionDate = useMemo(() => new Date(date).toLocaleDateString(navigator.language), [date])

  const sizedName = useMemo(() => {
    if (!name) return null
    const fullPatientName = name.split(' ')
    const numberOfNames = fullPatientName.length
    if (numberOfNames > 4) {
      return fullPatientName.slice(0, 2).join(' ') + ' ' + fullPatientName.slice(numberOfNames - 2).join(' ')
    }
    return name
  }, [name])

  return <button
    type="button"
    className="list-group-item list-group-item-action noOutline"
    style={{
      backgroundColor: `${id === selectedItem ? '#DDDDDD' : ''}`,
      borderColor: id === selectedItem ? 'white' : '',
      borderWidth: id === selectedItem ? 2 : '',
      fontSize: `${id === selectedItem ? '1.15em' : ''}`,
      color: id === selectedItem ? '#03C1AF' : 'white'
    }}
    onClick={selectPrescription}>
    {sizedName && <div className={`${id === selectedItem ? 'selectedItem' : ''} prescription-name font-weight-bold `}>{sizedName}</div>}
    <div className={`${id === selectedItem ? 'selectedItem' : ''} prescription-details font-weight-bold`}>Nº SNS: {healthId}</div>
    <div className={`${id === selectedItem ? 'selectedItem' : ''} prescription-details font-weight-bold`}>Data: {prescriptionDate}</div>
  </button>
})

SideBarItem.propTypes = {
  name: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  prescription: PropTypes.instanceOf(Prescription),
  selectedPrescription: PropTypes.func,
  selectedItem: PropTypes.string
}

export default SideBarItem
