import React, { useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'

import { CardItemsInputGroup } from './card_item/card_items'

import './card.css'
import moment from 'moment'
import EmailField from './EmailField'
import { SHARED_WITH_TYPES, SIGNING_PROCESS, SIGNING_PROGRESS_TO_TEXT, VALIDATE_EMAIL_REGEX } from '../../../constants'
import { useTranslate } from 'react-polyglot'
import SpmsProcessError from './process_error'
import { usePolyglot } from '../../../hooks'

const MAX_EMAILS = 4

const ItemsLabels = React.memo(function ItemLabels () {
  const t = useTranslate()
  return <div className='d-flex flex-column justify-content-center align-content-center mb-2 px-auto'>
    <span className='align-self-center font-weight-bold itemsLabel'>{t('card.prescription.labels.items')}</span>
    <div className='d-flex justify-content-between align-content-center mx-5'>
      <span className='font-weight-bold itemsLabel'>{t('card.prescription.labels.nameItem')}</span>
      <span className='font-weight-bold itemsLabel'>{t('card.prescription.labels.packageItem')}</span>
    </div>
  </div>
})

const Card = React.memo(function Card ({
  collapsed: startCollapsed, number, items, shouldShowSignButton, prescription, addEmails, patientEmail,
  addEmailsAndEmitPrescription
}) {
  const t = usePolyglot('card')
  const [collapsed, setCollapsed] = useState(startCollapsed)
  const [signed, setSigned] = useState(false)
  const [disabledEmailEditing, setDisabledEmailEditing] = useState(false)
  const [signatureProcess, setSignatureProcess] = useState(SIGNING_PROCESS.NOT_STARTED)
  const [errorsSignature, setErrorsSignature] = useState(null)

  const [emails, setEmails] = useState(prescription.sharedWith && prescription.sharedWith.length > 0 ? prescription.sharedWith.map(entry => ({
    value: entry.value,
    valid: true
  })) : [{
    value: '',
    valid: false
  }])

  const collapse = useCallback(() => setCollapsed(false), [])

  const internalSetSignatureProcess = useCallback((state, errors = null) => {
    setErrorsSignature(errors)
    setSignatureProcess(state)
  }, [setErrorsSignature, setSignatureProcess])

  const resetSignatureProcess = useCallback(() => {
    setSignatureProcess(SIGNING_PROCESS.NOT_STARTED)
  }, [setSignatureProcess])

  useEffect(() => {
    if (signatureProcess === SIGNING_PROCESS.PRESCRIBED) {
      setSigned(true)
      setErrorsSignature(null)
      collapse()
    }
  }, [signatureProcess, collapse])

  const sign = useCallback(() => {
    setDisabledEmailEditing(true)
    addEmailsAndEmitPrescription(emails.map(em => em.value), internalSetSignatureProcess)
  }, [emails, addEmailsAndEmitPrescription, internalSetSignatureProcess])

  const updateEmail = useCallback((index, newValue) => {
    setEmails(oldEmails => {
      const newEMails = [...oldEmails]
      newEMails[index] = { value: newValue, valid: VALIDATE_EMAIL_REGEX.test(newValue) }
      return newEMails
    })
  }, [])

  const removeEmail = useCallback(index => {
    setEmails(ems => [...ems.slice(0, index), ...ems.slice(index + 1)])
  }, [])

  const addEmail = useCallback(() => {
    setEmails(ems => [...ems, { value: '', valid: false }])
  }, [])

  const allEmailsValid = useMemo(() => !emails.find(e => !e.valid), [emails])

  const signatureProgress = useMemo(() => SIGNING_PROGRESS_TO_TEXT[signatureProcess], [signatureProcess])

  const prescriptionCreatedAt = useMemo(() => ({ date: moment(prescription.createdAt).format('L-LTS') }), [prescription])

  return <div className='card mb-5'>
    <div className='card-header' id={`card-header-${number}`}>
      <div className='d-flex flex-column flex-lg-row justify-content-center align-content-center '>
        <span>{t('prescription.createdAt', prescriptionCreatedAt)}</span>
      </div>
      {collapsed ?
        <div>
          {signed ?
            <div>
              <div className='sign-text d-flex justify-content-center'>Assinada</div>
              <div className='d-flex justify-content-center'>
                <button type='button' className={`btn ${collapsed ? 'collapsed' : ''}`} data-toggle='collapse'
                        data-target={`collapse-${number}`} aria-expanded='false' aria-controls={`collapse-${number}`}
                        onClick={collapse}>
                  <i className='expand-card fas fa-chevron-circle-down fa-2x' />
                </button>
              </div>
            </div>
            : <div className='d-flex justify-content-center'>
              <button type='button' className={`btn ${collapsed ? 'collapsed' : ''}`} data-toggle='collapse'
                      data-target={`collapse-${number}`} aria-expanded='false' aria-controls={`collapse-${number}`}
                      onClick={collapse}>
                <i className='expand-card fas fa-chevron-circle-down fa-2x' />
              </button>
            </div>}
        </div>
        : null}
    </div>
    <div id={`collapse-${number}`} className={`collapse ${collapsed ? '' : 'show'}`}
         aria-labelledby={`card-header-${number}`}>
      <div className='card-body'>
        {!signed && <>
          <div className='d-flex flex-column justify-content-center align-content-stretch mb-lg-2'>
            {Object.keys(prescription.sharedWith).length === 0 ? emails.map((email, index) => <EmailField
                key={index} value={email} setValue={updateEmail.bind(null, index)}
                isRemove={index !== 0} editable={!disabledEmailEditing && (index !== 0 || !patientEmail)}
                addButton={index !== 0 ? removeEmail.bind(null, index) : emails.length <= MAX_EMAILS ? addEmail : null} />)
              : Object.values(prescription.sharedWith).filter(sw => sw.type === SHARED_WITH_TYPES.EMAIL).map(sw =>
                <EmailField key={sw.id} value={{ value: sw.value, valid: true }} hideButton editable={false}
                            addButton={null} />)
            }
          </div>
          <div className='d-flex flex-column justify-content-center align-content-stretch'>
            <ItemsLabels />
            <CardItemsInputGroup items={items} />
          </div>
          {(!signed && signatureProcess === SIGNING_PROCESS.NOT_STARTED) &&
            <div className='d-flex justify-content-center'>
              <button
                type='submit' className='btn btn-success active sign-button mt-2'
                disabled={!shouldShowSignButton || !allEmailsValid}
                onClick={sign}>
                {t('sign')}
              </button>
            </div>}
        </>}
        {signatureProcess !== SIGNING_PROCESS.NOT_STARTED &&
          <div className='d-flex flex-column align-content-stretch mx-5 mt-4 '>
            <div className='progress progressBar'>
              <div
                className={`progress-bar progress-bar-striped progress-bar-animated ${signatureProcess % 2 === 0 ? 'bg-success' : 'bg-danger'}`}
                style={{ width: `${signatureProgress.progress}%` }} />
            </div>
            {signatureProgress.text.map((val, index) => <span key={index}
                                                               className={`align-self-center mt-1 ${index === 0 ? 'progressText' : 'secondaryProgressText'} ${signatureProgress?.error ? 'errorText' : ''}`}>
              {t(`progress.${signatureProcess}.${val}`)}
            </span>)}
            {(signatureProcess % 2) !== 0 &&
              <div className='d-flex flex-row justify-content-center align-content-center'>
                <button
                  type='submit' className='btn btn-info active sign-button mt-2'
                  onClick={resetSignatureProcess}>
                  {t('reset')}
                </button>
              </div>}
            {signatureProgress?.external && Object.keys(errorsSignature).map(errorKey => <SpmsProcessError
              errorKey={errorKey}
              key={errorKey}
              value={errorsSignature[errorKey].description}
            />)}
          </div>}
      </div>
    </div>
  </div>
})

Card.propTypes = {
  number: PropTypes.number.isRequired,
  items: PropTypes.array.isRequired,
  shouldShowSignButton: PropTypes.bool.isRequired,
  prescription: PropTypes.object.isRequired
}

export default Card
