import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import SideBarItem from './side_bar_item/side_bar_item'

import './side_bar.css'

const LoadingPrescriptions = React.memo(function LoadingPrescriptions () {
  return <div className="d-flex align-items-center justify-content-center text-center">
    A carregar as prescrições por assinar
  </div>
})

const ScriboBranding = React.memo(function ScriboBranding () {
  return <div className="sidebar-heading d-flex align-items-center px-4 py-2">
    <a href="/">
      <img className="scribo-logo" src="/scribo_logo.png" alt="Scribo logo" />
    </a>
    <img className="scribo-brand pl-2 pb-1" src="/scribo_brand.png" alt="Scribo brand" />
  </div>
})

export const SideBar = React.memo(function SideBar ({ prescriptions, patients, selectedPrescription }) {
  const [selectedId, setSelectedId] = useState(null)

  useEffect(() => () => console.log(`SideBard will unmount`), [])

  const selectPrescription = useCallback(prescription => {
    selectedPrescription(prescription)
    setSelectedId(prescription.id)
  }, [selectedPrescription])

  return <div className="d-flex flex-column border-right" style={{flex: '1 1 auto'}} id="sidebar-wrapper"><ScriboBranding />
    <div className="sidebar-sub-heading d-flex align-items-center px-4 py-3">Prescrições por Assinar</div>
    <div className="list-group list-group-flush overflow-auto">
      {!prescriptions ? <LoadingPrescriptions /> : <ul className="list-group list-group-flush">
        {prescriptions.map(p => <SideBarItem
          key={p.id}
          id={p.id}
          healthId={p.healthId}
          name={patients[p.healthId]?.fullName}
          date={p.createdAt}
          selectPrescription={selectPrescription.bind(null, p)}
          selectedItem={selectedId}
        />)}
      </ul>}
    </div>
  </div>
})

SideBar.propTypes = {
  prescriptions: PropTypes.array.isRequired,
  selectedPrescription: PropTypes.func.isRequired
}
