import React, { useCallback } from 'react'
import PropTypes from 'prop-types'

import './card.css'

const EmailField = React.memo(function EmailField ({
  value, valid, setValue, addButton, isRemove, editable
}) {
  const handleChange = useCallback((event) => {
    setValue(event.target.value)
  }, [setValue])

  return <div className={`input-group input-group-lg mb-3 px-5`}>
    <div className='input-group-prepend'>
      <span className='input-group-text font-weight-bold'>E-mail: </span>
    </div>
    <input type='email'
           className={`form-control email ${value.valid ? 'is-valid' : 'is-invalid'}`} //className={editable ? `form-control email ${value.valid ? 'is-valid' : 'is-invalid'}` : `form-control-plaintext readOnlyEmail ${value.valid ? 'is-valid' : 'is-invalid'}`}
           readOnly={!editable}
           placeholder='Introduzir o endereço do utente e cuidadores' aria-label='Email' value={value.value}
           onChange={handleChange} />
    {addButton && <div className='input-group-append'>
      <button className={`btn btn-${isRemove ? 'danger' : 'primary'} `} type='button'
              onClick={addButton}>
        <span className='buttonAddRemove'>{isRemove ? '-' : '+'}</span>
      </button>
    </div>}
  </div>
})

EmailField.propTypes = {
  value: PropTypes.shape({
    value: PropTypes.string.isRequired,
    valid: PropTypes.bool.isRequired
  }).isRequired,
  setValue: PropTypes.func,
  editable: PropTypes.bool.isRequired,
  addButton: PropTypes.func
}

export default EmailField
